class Config {
  public ChatBotUrl = 'https://chatbot.convorally.com/';
  
  // local
  // public apiBaseUrl = 'http://localhost:8000/api/v1/';
  // public privateChannel = 'http://localhost:8000/api/';
  // public apiMangoBaseUrl = 'http://localhost:3000/api/';
  // public liveImageBaseUrl = 'http://localhost:8000/';
  // public logOutUrl = 'https://localhost:8080/';
  // public imageBaseUrl = 'https://localhost:8080/';
  // public redirect_uri = 'https://localhost:8080/#/appointment/';

  public client_id = 'SeVsOu0CS9HnzTrBaOKwg';
  public response_type = 'code';
  //**************************************** Zoom Api Start ******************************************************** */
  public zoomAuth ='https://zoom.us/oauth/authorize';
  public zoomAccess ='https://zoom.us/oauth/token';
  public zoomApi ='https://api.zoom.us/v2/';
  
  //**************************************** Zoom Api End ******************************************************** */
  
  //live
  // public imageBaseUrl = 'app/';
  // public apiBaseUrl = 'https://app.convorally.com/api/v1/';
  // public privateChannel = 'https://app.convorally.com/api/';
  // public liveImageBaseUrl = 'https://app.convorally.com/';
  // public logOutUrl = 'https://app.convorally.com/';
  // public apiMangoBaseUrl = 'https://ethitrans-form-api.travlize.com/api/';
  // public redirect_uri = 'https://app.convorally.com/#/appointment/';

 public imageBaseUrl = 'app/';
  public apiBaseUrl = 'https://qa.convorally.com/api/v1/';
  public privateChannel = 'https://qa.convorally.com/api/';
  public liveImageBaseUrl = 'https://qa.convorally.com/';
  public logOutUrl = 'https://qa.convorally.com/';
  public redirect_uri = 'https://qa.convorally.com/#/appointment/';
  public apiMangoBaseUrl = 'https://ethitrans-form-api.travlize.com/api/';
  
    //  cookies expires after 8 days
  public cookiesExpiryDate = 60 * 60 * 8;
}
export const config = new Config();
// slot-calender backup uptoslot with allday


// smartcontractlist button